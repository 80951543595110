import React from "react";
import { Admin } from "react-admin";
import "./App.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import AuthProvider from "./authProvider/AuthProvider";
import postgrestRestProvider from "@raphiniert/ra-data-postgrest";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import SuonoTheme from "./layout/suono/Theme";
import { SuonoLayout } from "./layout/suono/Layout";

import { ProjectResource } from "./pages/resources/Projects";
import { TimeResource } from "./pages/resources/Time";
import { ExpensesResource } from "./pages/resources/Expenses";

const MainApp = () => {
  const authProvider = AuthProvider();
  const dataProvider = postgrestRestProvider(
    process.env.REACT_APP_API_ENDPOINT,
    authProvider.httpClient
  );

  return (
    <Admin
      disableTelemetry
      theme={SuonoTheme}
      layout={SuonoLayout}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      {ProjectResource()}
      {TimeResource()}
      {ExpensesResource()}
    </Admin>
  );
};

const App = () => {
  useMsalAuthentication("redirect");

  return (
    <ThemeProvider theme={SuonoTheme}>
      <CssBaseline />
      <AuthenticatedTemplate>
        <MainApp />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate></UnauthenticatedTemplate>
    </ThemeProvider>
  );
};

export default App;
