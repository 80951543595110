import React from "react";
import { useState } from "react";
import { Datagrid, useListContext, useRedirect } from "react-admin";

const SingleSelectDatagrid = ({ children, onDoubleClick, ...props }) => {
  const { onSelect, onUnselectItems, selectedIds, resource } = useListContext();
  const [lastId, setLastId] = useState();
  const redirect = useRedirect();

  const singleSelect = (id) => {
    setLastId(id);
    if (selectedIds.includes(id)) {
      onUnselectItems();
    } else {
      onUnselectItems();
      onSelect([id]);
    }
  };

  const rowStyle = (record) => {
    return {
      backgroundColor: selectedIds.includes(record.id) ? "lightGrey" : "white",
    };
  };

  const doubleClick = () => {
    if (onDoubleClick) {
      onDoubleClick(lastId);
    } else {
      redirect(`/${resource}/${lastId}`);
    }
  };

  return (
    <Datagrid
      {...props}
      rowClick={singleSelect}
      rowStyle={rowStyle}
      onDoubleClick={doubleClick}
    >
      {children}
    </Datagrid>
  );
};

export default SingleSelectDatagrid;
