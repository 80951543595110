/* eslint-disable no-console */
import authenticatedClient from './authenticatedClient';
import { useIsAuthenticated, useMsal, useAccount } from '@azure/msal-react';

/**
 * Provider for the authentication capability
 */
const AuthProvider = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const getToken = async () =>
    instance
      .acquireTokenSilent({
        scopes: ['User.Read'],
        account: account,
      })
      .then((response) => response.idToken);

  const checkAuth = async () =>
    isAuthenticated ? Promise.resolve() : Promise.reject();

  return {
    httpClient: authenticatedClient(getToken, checkAuth),

    checkAuth,

    getPermissions: async () => {
      const claims = account.idTokenClaims;
      return claims.roles || ['user'];
    },

    getIdentity: async () => ({
      fullName: account.name + '\n' + account.idTokenClaims.preferred_username,
      email: account.idTokenClaims.preferred_username,
      avatar: account.idTokenClaims.preferred_username,
    }),

    logout: () => {
      instance.logoutRedirect();
    },

    login: () => {
      instance.loginRedirect();
    },
  };
};

export default AuthProvider;
