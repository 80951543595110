import React from "react";
import {
  Resource,
  TextField,
  DateField,
  ReferenceField,
  List,
} from "react-admin";
import SingleSelectDatagrid from "./components/SingleSelectDatagrid";
import { useMediaQuery } from "@mui/material";
import SimpleList from "./components/SuonoSimpleList";
import { SidebarMenuItemIcon } from "../../layout/suono/SidebarMenuItemIcon";

export const ProjectList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <List {...props} bulkActionButtons={false}>
      {isSmall ? (
        <ProjectSimpleList {...props} />
      ) : (
        <ProjectListGrid {...props} />
      )}
    </List>
  );
};

export const ProjectSimpleList = ({ onClick, onDoubleClick }) => (
  <SimpleList
    primaryText={(record) => record.code}
    secondaryText={(record) => `${record.name}`}
    tertiaryText={(record) => new Date(record.date).toLocaleDateString()}
    linkType={false}
  />
);

export const ProjectListGrid = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return !isSmall ? (
    <SingleSelectDatagrid rowClick="edit" {...props}>
      <TextField source="code" label="Index" />
      <TextField source="name" />
      <DateField source="date" />
      <ReferenceField
        label="State"
        source="project_state_id"
        reference="project_state"
        variant="outlined"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Owner"
        source="owner_id"
        reference="staff"
        variant="outlined"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="estimated_fee" label="Estimated Fee" />
      <TextField source="wip" label="WIP" />
    </SingleSelectDatagrid>
  ) : (
    <ProjectSimpleList {...props} />
  );
};

export const ProjectResource = () => (
  <Resource
    key="project"
    name="project"
    icon={SidebarMenuItemIcon("i")}
    list={ProjectList}
  />
);
