export const config = {
  auth: {
    authority:
      'https://login.microsoftonline.com/ec43c779-f2f9-4c16-a039-ba4f0a7e3e0f/',

    authorityMetadata: JSON.stringify({
      token_endpoint:
        'https://login.microsoftonline.com/ec43c779-f2f9-4c16-a039-ba4f0a7e3e0f/oauth2/v2.0/token',
      token_endpoint_auth_methods_supported: [
        'client_secret_post',
        'private_key_jwt',
        'client_secret_basic',
      ],
      jwks_uri:
        'https://login.microsoftonline.com/ec43c779-f2f9-4c16-a039-ba4f0a7e3e0f/discovery/v2.0/keys',
      response_modes_supported: ['query', 'fragment', 'form_post'],
      subject_types_supported: ['pairwise'],
      id_token_signing_alg_values_supported: ['RS256'],
      response_types_supported: [
        'code',
        'id_token',
        'code id_token',
        'id_token token',
      ],
      scopes_supported: ['openid', 'profile', 'email', 'offline_access'],
      issuer:
        'https://login.microsoftonline.com/ec43c779-f2f9-4c16-a039-ba4f0a7e3e0f/v2.0',
      request_uri_parameter_supported: false,
      userinfo_endpoint: 'https://graph.microsoft.com/oidc/userinfo',
      authorization_endpoint:
        'https://login.microsoftonline.com/ec43c779-f2f9-4c16-a039-ba4f0a7e3e0f/oauth2/v2.0/authorize',
      device_authorization_endpoint:
        'https://login.microsoftonline.com/ec43c779-f2f9-4c16-a039-ba4f0a7e3e0f/oauth2/v2.0/devicecode',
      http_logout_supported: true,
      frontchannel_logout_supported: true,
      end_session_endpoint:
        'https://login.microsoftonline.com/ec43c779-f2f9-4c16-a039-ba4f0a7e3e0f/oauth2/v2.0/logout',
      claims_supported: [
        'sub',
        'iss',
        'cloud_instance_name',
        'cloud_instance_host_name',
        'cloud_graph_host_name',
        'msgraph_host',
        'aud',
        'exp',
        'iat',
        'auth_time',
        'acr',
        'nonce',
        'preferred_username',
        'name',
        'tid',
        'ver',
        'at_hash',
        'c_hash',
        'email',
      ],
      kerberos_endpoint:
        'https://login.microsoftonline.com/ec43c779-f2f9-4c16-a039-ba4f0a7e3e0f/kerberos',
      tenant_region_scope: 'EU',
      cloud_instance_name: 'microsoftonline.com',
      cloud_graph_host_name: 'graph.windows.net',
      msgraph_host: 'graph.microsoft.com',
      rbac_url: 'https://pas.windows.net',
    }),

    cloudDiscoveryMetadata: JSON.stringify({
      tenant_discovery_endpoint:
        'https://login.microsoftonline.com/ec43c779-f2f9-4c16-a039-ba4f0a7e3e0f/v2.0/.well-known/openid-configuration',
      'api-version': '1.1',
      metadata: [
        {
          preferred_network: 'login.microsoftonline.com',
          preferred_cache: 'login.windows.net',
          aliases: [
            'login.microsoftonline.com',
            'login.windows.net',
            'login.microsoft.com',
            'sts.windows.net',
          ],
        },
        {
          preferred_network: 'login.partner.microsoftonline.cn',
          preferred_cache: 'login.partner.microsoftonline.cn',
          aliases: [
            'login.partner.microsoftonline.cn',
            'login.chinacloudapi.cn',
          ],
        },
        {
          preferred_network: 'login.microsoftonline.de',
          preferred_cache: 'login.microsoftonline.de',
          aliases: ['login.microsoftonline.de'],
        },
        {
          preferred_network: 'login.microsoftonline.us',
          preferred_cache: 'login.microsoftonline.us',
          aliases: ['login.microsoftonline.us', 'login.usgovcloudapi.net'],
        },
        {
          preferred_network: 'login-us.microsoftonline.com',
          preferred_cache: 'login-us.microsoftonline.com',
          aliases: ['login-us.microsoftonline.com'],
        },
      ],
    }),
    clientId: '29f61fd7-8c05-4e5a-81d7-6c941158419d',
    redirectUri: process.env.REACT_APP_REDIRECT,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};
