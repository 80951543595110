import React from "react";
import { ListGuesser, Resource } from "react-admin";
import { SidebarMenuItemIcon } from "../../layout/suono/SidebarMenuItemIcon";

export const ExpensesResource = () => [
  <Resource
    key="expense"
    name="expense"
    icon={SidebarMenuItemIcon("£")}
    list={ListGuesser}
  />,
];
