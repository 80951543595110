import React from "react";
import { Resource, ListGuesser } from "react-admin";
import { SidebarMenuItemIcon } from "../../layout/suono/SidebarMenuItemIcon";

export const TimeResource = () => [
  <Resource
    key="time"
    name="time"
    icon={SidebarMenuItemIcon("t")}
    list={ListGuesser}
  />,
];
