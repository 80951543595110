import React from "react";
import { Sidebar } from "react-admin";

export const SuonoSidebar = (props) => (
  <Sidebar
    sx={{
      backgroundColor: "primary.background",
    }}
    {...props}
  />
);
