import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import React, { Fragment } from "react";
import { sanitizeListRestProps, useListContext } from "ra-core";

const SimpleList = (props) => {
  const {
    className,
    classes: classesOverride,
    hasBulkActions,
    leftAvatar,
    leftIcon,
    linkType = "edit",
    primaryText,
    rightAvatar,
    rightIcon,
    secondaryText,
    tertiaryText,
    rowStyle,
    onClick,
    onDoubleClick,
    ...rest
  } = props;
  const { data, ids, loaded, total, selectedIds, onSelect } = useListContext(
    props
  );

  if (loaded === false) {
    return <Fragment />;
  }

  const renderAvatar = (id, avatarCallback) => {
    const avatarValue = avatarCallback(data[id], id);
    if (
      typeof avatarValue === "string" &&
      (avatarValue.startsWith("http") || avatarValue.startsWith("data:"))
    ) {
      return <Avatar src={avatarValue} />;
    } else {
      return <Avatar>{avatarValue}</Avatar>;
    }
  };

  return (
    total > 0 && (
      <List className={className} {...sanitizeListRestProps(rest)}>
        {ids.map((id, rowIndex) => (
          <ListItem
            key={id}
            onClick={() => onSelect([id])}
            onDoubleClick={onDoubleClick}
            button={!!linkType}
            selected={selectedIds.includes(id)}
            style={rowStyle ? rowStyle(data[id], rowIndex) : undefined}
          >
            {leftIcon && <ListItemIcon>{leftIcon(data[id], id)}</ListItemIcon>}
            {leftAvatar && (
              <ListItemAvatar>{renderAvatar(id, leftAvatar)}</ListItemAvatar>
            )}
            <ListItemText
              primary={
                <div>
                  {primaryText(data[id], id)}
                  {tertiaryText && (
                    <span sx={{ float: "right", opacity: 0.541176 }}>
                      {tertiaryText(data[id], id)}
                    </span>
                  )}
                </div>
              }
              secondary={secondaryText && secondaryText(data[id], id)}
            />
            {(rightAvatar || rightIcon) && (
              <ListItemSecondaryAction>
                {rightAvatar && (
                  <Avatar>{renderAvatar(id, rightAvatar)}</Avatar>
                )}
                {rightIcon && (
                  <ListItemIcon>{rightIcon(data[id], id)}</ListItemIcon>
                )}
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    )
  );
};

export default SimpleList;
