import { fetchUtils } from 'react-admin';

/**
 * HTTP client customised to work with our solution
 *
 * Adds necessary auth and transaction headers to all requests
 *
 * @param {string} url URL to request
 * @param {Object} options options to pass to fetchUtils
 */
const authenticatedClient =
  (getAuthToken, checkAuth) =>
  async (url, options = {}) => {
    const newOptions = { ...options, headers: new Headers(options.headers) };
    const token = await getAuthToken();
    if (token) {
      newOptions.headers.set('Authorization', `Bearer ${token}`);
    }

    return fetchUtils.fetchJson(url, newOptions).then((response) => {
      if (response.status === 401 && checkAuth) {
        return checkAuth();
      }

      return response;
    });
  };

export default authenticatedClient;
