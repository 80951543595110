import React from "react";
import { AppBar } from "react-admin";
import { Grid } from "@mui/material";
import { SuonoMenu } from "./UserMenu";
import logo from "../../static/suono-logo static.svg";

export const SuonoAppBar = (props) => (
  <AppBar {...props} userMenu={<SuonoMenu />}>
    <Grid container alignContent="flex-start" justifyContent="flex-start">
      <Grid item>
        <img src={logo} height="50px" alt="Suono Logo" />
      </Grid>
    </Grid>
  </AppBar>
);
