import React from "react";
import { UserMenu } from "react-admin";
import Gravitar from "react-circle-gravatar";
import { useGetIdentity } from "ra-core";

export const SuonoMenu = (props) => {
  const { loaded, identity } = useGetIdentity();
  return (
    <UserMenu
      {...props}
      icon={<Gravitar size={40} email={(loaded && identity.email) || ""} />}
    ></UserMenu>
  );
};
