/* eslint-disable prefer-template */
import { createTheme } from "@mui/material/styles";

export const SuonoOrange = "rgb(255, 166, 0)";
export const SuonoDarkOrange = "rgb(225, 145, 0)";
export const SuonoLightGrey = "rgb(200, 200, 200)";
export const SuonoGrey = "rgb(150, 150, 150)";
export const SuonoDarkGrey = "rgb(100, 100, 100)";
export const SuonoGreyBlue = "rgb(144, 178, 203)";
export const SuonoDarkBlue = "rgb(5, 30, 50)";
export const SuonoBlue = "rgb(10, 85, 140)";
export const SuonoLightBlue = "rgb(127, 172, 255)";
export const SuonoRed = "rgb(230, 0, 0)";
export const SuonoYellow = "rgb(255, 252, 121)";
export const SuonoBlack = "rgb(0, 0, 0)";
export const SuonoWhite = "rgb(255, 255, 255";

const SuonoTheme = () =>
  createTheme({
    typography: {
      fontFamily: ["HelveticaNeueCyr-Light", "Arial", "sans-serif"].join(","),
      h2: {
        fontWeight: "bold",
      },
      h3: {
        fontWeight: "bold",
      },
      h5: {
        fontWeight: "bold",
      },
    },

    palette: {
      type: "light",
      primary: {
        main: SuonoBlue,
        background: SuonoLightGrey,
      },
      secondary: {
        main: SuonoOrange,
        dark: SuonoDarkBlue,
        background: SuonoWhite,
      },
      error: {
        main: SuonoRed,
      },
      warning: {
        main: SuonoOrange,
        light: SuonoYellow,
      },
      info: {
        main: SuonoLightBlue,
      },
      success: {
        main: SuonoLightBlue,
      },
      disabled: {
        main: "lightgrey",
      },
      night: {
        hour: "rgb(0,0,0,0.1)",
        subHour: "rgb(0,0,0,0.05)",
      },
      day: {
        hour: "rgb(0,0,0,0.18)",
        subHour: "rgb(0,0,0,0.12)",
      },
    },
    sidebar: {
      width: 240,
      closedWidth: 55,
    },
  });

export default SuonoTheme();
