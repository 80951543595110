import React from "react";

export const SidebarMenuItemIcon = (text) => (props) => (
  <div
    style={{
      borderRadius: "150px",
      border: "black solid 3px",
      width: "32px",
      height: "32px",
      bottom: "-4px",
      textAlign: "center",
      fontSize: "20px",
      fontFamily: "'Courier New', Courier, monospace",
      fontWeight: "700",
    }}
  >
    {text}
  </div>
);
